import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, doc, setDoc, query, where, getDocs, writeBatch, orderBy, limit, QueryDocumentSnapshot, DocumentData, startAfter } from "firebase/firestore";
import { memberNames } from "../data/MemberNames";
import axios from "axios";

const firebaseConfig = {
  apiKey: "AIzaSyCxxopr_wm61s4EE5Y4WgzOPtDOKRck1J4",
  authDomain: "mep-vote.firebaseapp.com",
  projectId: "mep-vote",
  storageBucket: "mep-vote.appspot.com",
  messagingSenderId: "518016828032",
  appId: "1:518016828032:web:adead870fb2fb351699c65",
  measurementId: "G-4YQ2DHPXJJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export const addMembersToFirestore = async (memberDatabase: { id: string; name: string }[]) => {    
    try {
      for (const member of memberDatabase) {
        // Set document with member ID to avoid duplicate members
        console.log(member);
        
        await setDoc(doc(db, "members", member.id.toString()), member);
      }
      console.log("Members successfully added to Firestore!");
    } catch (error) {
      console.error("Error adding members to Firestore: ", error);
    }
  };

  export const addVoteToFirebase = async (voteDatabase: { id: string; name: string }[]) => {
    
    try {
      for (const vote of voteDatabase) {
        // Set document with member ID to avoid duplicate members
        await setDoc(doc(db, "votes", vote.id.toString()), vote);
      }
      console.log("Members successfully added to Firestore!");
    } catch (error) {
      console.error("Error adding members to Firestore: ", error);
    }
  };

  export const findMemberWithName = async (name: string | {first_name: string, last_name: string}) => {
    try {
    let nameParts = ['', '']
    if(typeof name !== 'object') {
      // Split the name into parts
      nameParts = name.split(" ");
        
      // Handle edge cases where there are fewer or more parts in the name
      if (nameParts.length < 2) {
          console.error("Name should include at least a first name and a last name. Received: " + nameParts);
          return null;
      }
    }
      // Assume the first name is the first part and the rest are last name
      const firstName = typeof name == 'object' ? name.first_name : nameParts[0];
      const lastName = typeof name == 'object' ? name.last_name : nameParts.slice(1).join(" ").toUpperCase();
    
  
      // Reference to the members collection
      const membersRef = collection(db, "members");
  
      // Create a query against the collection
      const q = query(
        membersRef,
        where("first_name", "==", firstName),
        where("last_name", "==", lastName.toUpperCase())
      );
      
      // Execute the query
      const querySnapshot = await getDocs(q);
  
      // Check if any documents were found
      if (querySnapshot.empty) {
        console.log("No matching documents.");
        return null;
      }
  
      // Process and return the found documents
      const members: any[] = [];
      querySnapshot.forEach((doc) => {
        members.push({ id: doc.id, ...doc.data() });
      });
  
      return members;
    } catch (error) {
      console.error("Error finding member: ", error);
      return null;
    }
  };
  
  const capitalizeName = (name: string): string => {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };
  
  export const fetchMemberNames = async (): Promise<readonly MemberOption[]> => {
    const membersCollection = collection(db, "members");
    const memberSnapshot = await getDocs(membersCollection);
    const memberOptions: MemberOption[] = [];
  
    memberSnapshot.forEach((doc) => {
      const data = doc.data();
      const firstName = capitalizeName(data.first_name);
      const lastName = capitalizeName(data.last_name);
      const fullName = `${firstName} ${lastName}`;
  
      memberOptions.push({ value: `${data.first_name} ${data.last_name}`, label: fullName });
    });
  
    return memberOptions as readonly MemberOption[];
  };

  interface MemberOption {
    value: string | {first_name: string, last_name: string};
    label: string;
  }
  
  interface IMember {
    id: number;
    first_name: string;
    last_name: string;
  }
  
  interface IVoteDetails {
    id: number;
    display_title: string;
    description: string;
    timestamp: string;
    member_votes: IMemberVote[];
    is_featured: boolean;
  }
  
  interface IMemberVote {
    member: IMember;
    position: "ABSTENTION" | "DID_NOT_VOTE" | "AGAINST" | "FOR";
  }
  
  interface Vote {
    id: number;
    display_title: string;
    description: string;
    timestamp: string;
    position: "ABSTENTION" | "DID_NOT_VOTE" | "AGAINST" | "FOR";
    is_featured: boolean;
  }

  interface Procedure {
    title: string;
    reference: string;
  }
  
  interface Stats {
    FOR: number;
    AGAINST: number;
    ABSTENTION: number;
    DID_NOT_VOTE: number;
  }
  
  interface CountryStats {
    country: Record<string, unknown>;
    stats: Record<string, unknown>;
  }
  
  interface GroupStats {
    group: Record<string, unknown>;
    stats: Record<string, unknown>;
  }
  
  interface StatsDetail {
    total: Stats;
    by_country: CountryStats[];
    by_group: GroupStats[];
  }

  interface Source {
    name: string;
    url: string;
    accessed_at: string;
  }
  
  interface Related {
    id: number;
    timestamp: string;
    description: string;
  }
  
  interface GeoArea {
    code: string;
    iso_alpha_2: string;
    label: string;
  }
  
  interface EurovocConcept {
    id: number;
    label: string;
  }
  
  interface IVoteDetails {
    procedure: Procedure;
    facts: string;
    sharepic_url: string;
    stats: StatsDetail;
    member_votes: IMemberVote[];
    sources: Source[];
    related: Related[];
    id: number;
    timestamp: string;
    display_title: string;
    reference: string;
    description: string;
    is_featured: boolean;
    geo_areas: GeoArea[];
    eurovoc_concepts: EurovocConcept[];
  }
  
  const fetchMemberVotes = async (mep: IMember): Promise<Vote[]> => {
    let currentPage = 1;
    let memberVotes: Vote[] = [];
    let hasNextPage = true;
  
    while (hasNextPage) {
      const votesResponse = await axios.get('https://howtheyvote.eu/api/votes', {
        params: { page: currentPage, page_size: 100 },
      });
      const allVotes = votesResponse.data.results;
  
      for (const vote of allVotes) {
        const voteDetailsResponse = await axios.get(`https://howtheyvote.eu/api/votes/${vote.id}`);
        const voteDetails: IVoteDetails = voteDetailsResponse.data;
  
        const memberVote = voteDetails.member_votes.find((mv: IMemberVote) => mv.member.id === mep.id);
        if (memberVote) {
          memberVotes.push({
            id: voteDetails.id,
            display_title: voteDetails.display_title,
            description: voteDetails.description,
            timestamp: voteDetails.timestamp,
            position: memberVote.position,
            is_featured: voteDetails.is_featured
          });
        }
      }
      hasNextPage = allVotes.length > 0;
      console.log(currentPage);
      
      currentPage++;
    }
  
    return memberVotes;
  };
  
  const storeVotesInFirestore = async (mep: IMember, votes: Vote[]): Promise<void> => {
    const memberRef = doc(db, 'members', mep.id.toString());
    const votesCollectionRef = collection(memberRef, 'votes');
  
    for (const vote of votes) {
      console.log(vote.id);
      const voteDocRef = doc(votesCollectionRef, vote.id.toString());
      await setDoc(voteDocRef, vote);
    }
  };
  
  export const processMembers = async (): Promise<void> => {


    for (const memberOption of memberNames) {
        if (excludeNames.includes(memberOption.label))
            continue;
        console.log(memberOption.label);
      const meps: IMember[] | null = await findMemberWithName(memberOption.value);
      const mep = meps && meps[0];
  
      if (!mep) {
        console.error(`MEP not found for name: ${memberOption.value}`);
        continue;
      }
  
      const memberVotes = await fetchMemberVotes(mep);
      await storeVotesInFirestore(mep, memberVotes);
    }
  };
  
  export const fetchVotesFromFirestore = async (mep: IMember, onlyFeatured: boolean = false, limitVotes?: number): Promise<Vote[]> => {
    try {
      const votesCollectionRef = collection(db, 'members', `${mep.id}`, 'votes');
      
      // Create a query against the collection based on the onlyFeatured flag and limit
      let q;
      if (onlyFeatured) {
        q = query(
          votesCollectionRef,
          where("is_featured", "==", true),
          orderBy('timestamp', 'desc'),
          ...(limitVotes ? [limit(limitVotes)] : [])
        );
      } else {
        q = query(
          votesCollectionRef,
          orderBy('timestamp', 'desc'),
          ...(limitVotes ? [limit(limitVotes)] : [])
        );
      }
  
      // Fetch votes based on the query
      const votesSnapshot = await getDocs(q);
  
      // Extract votes data
      const memberVotes: Vote[] = votesSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: data.id,
          display_title: data.display_title,
          description: data.description,
          timestamp: data.timestamp,
          position: data.position,
          is_featured: data.is_featured,
        };
      });
  
      return memberVotes;
    } catch (error) {
      console.error("Error fetching votes from Firestore: ", error);
      return [];
    }
  };

  export const fetchVotesFromFirestoreWithLastDoc = async (
    mep: IMember,
    onlyFeatured: boolean = false,
    limitVotes?: number,
    startAfterDoc?: QueryDocumentSnapshot<DocumentData>
  ): Promise<{ votes: Vote[], lastDoc: QueryDocumentSnapshot<DocumentData> | null }> => {
    try {
      const votesCollectionRef = collection(db, 'members', `${mep.id}`, 'votes');
      
      // Create a query against the collection based on the onlyFeatured flag, limit, and pagination
      let q;
      if (onlyFeatured) {
        q = query(
          votesCollectionRef,
          where("is_featured", "==", true),
          orderBy('timestamp', 'desc'),
          ...(limitVotes ? [limit(limitVotes)] : []),
          ...(startAfterDoc ? [startAfter(startAfterDoc)] : [])
        );
      } else {
        q = query(
          votesCollectionRef,
          orderBy('timestamp', 'desc'),
          ...(limitVotes ? [limit(limitVotes)] : []),
          ...(startAfterDoc ? [startAfter(startAfterDoc)] : [])
        );
      }
  
      // Fetch votes based on the query
      const votesSnapshot = await getDocs(q);
  
      // Extract votes data
      const memberVotes: Vote[] = votesSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: data.id,
          display_title: data.display_title,
          description: data.description,
          timestamp: data.timestamp,
          position: data.position,
          is_featured: data.is_featured,
        };
      });
  
      // Get the last document fetched
      const lastDoc = votesSnapshot.docs[votesSnapshot.docs.length - 1] || null;
  
      return { votes: memberVotes, lastDoc };
    } catch (error) {
      console.error("Error fetching votes from Firestore: ", error);
      return { votes: [], lastDoc: null };
    }
  };


  const findMemberWithNamePromise = async (name: string | {first_name: string, last_name: string}): Promise<IMember[]> => {
    let nameParts = ['', '']
    if(typeof name !== 'object')
    nameParts = name.split(' ');
    
    const firstName = typeof name === 'object' ? name.first_name : nameParts[0];
    const lastName = typeof name === 'object' ? name.last_name : nameParts.slice(1).join(' ').toUpperCase();
    
    const membersRef = collection(db, 'members');
    const q = query(membersRef, where("first_name", "==", firstName), where("last_name", "==", lastName));
    const querySnapshot = await getDocs(q);
  
    const members: IMember[] = [];
    querySnapshot.forEach((doc) => {
      members.push(doc.data() as IMember);
    });
  
    return members;
  };
  
  const fetchMemberVotesPromise = async (mep: IMember): Promise<Vote[]> => {
    let currentPage = 1;
    let memberVotes: Vote[] = [];
    let hasNextPage = true;
  
    while (hasNextPage) {
      const votesResponse = await axios.get('https://howtheyvote.eu/api/votes', {
        params: { page: currentPage, page_size: 100 },
      });
      const allVotes = votesResponse.data.results;
  
      const voteDetailsPromises = allVotes.map(async (vote: { id: number }) => {
        const voteDetailsResponse = await axios.get(`https://howtheyvote.eu/api/votes/${vote.id}`);
        const voteDetails: IVoteDetails = voteDetailsResponse.data;
        const memberVote = voteDetails.member_votes.find((mv: IMemberVote) => mv.member.id === mep.id);
  
        if (memberVote) {
          return {
            id: voteDetails.id,
            display_title: voteDetails.display_title,
            description: voteDetails.description,
            timestamp: voteDetails.timestamp,
            position: memberVote.position,
            is_featured: voteDetails.is_featured
          } as Vote;
        }
      });
  
      const voteDetailsResults = await Promise.all(voteDetailsPromises);
      memberVotes.push(...voteDetailsResults.filter(vote => vote) as Vote[]);
  
      hasNextPage = allVotes.length > 0;
      console.log(currentPage);
      
      currentPage++;
    }
  
    return memberVotes;
  };

  const storeVotesInFirestorePromise = async (mep: IMember, votes: Vote[]): Promise<void> => {
    const memberRef = doc(db, 'members', mep.id.toString());
    const votesCollectionRef = collection(memberRef, 'votes');
    const chunkSize = 500; // Firestore batch limit
  
    for (let i = 0; i < votes.length; i += chunkSize) {
      const chunk = votes.slice(i, i + chunkSize);
      const batch = writeBatch(db);
  
      chunk.forEach(vote => {
        console.log(vote);
        const voteDocRef = doc(votesCollectionRef, vote.id.toString());
        batch.set(voteDocRef, vote);
      });
  
      await batch.commit();
    }
  };

  const excludeNames = [
    ""
];
  
  export const processMembersPromise = async (): Promise<void> => {
    for (const memberOption of memberNames) {
        if (excludeNames.includes(memberOption.label))
            continue;
        console.log(memberOption.label);
        
      const meps: IMember[] | null = await findMemberWithNamePromise(memberOption.value);
      const mep = meps && meps[0];
  
      if (!mep) {
        console.error(`MEP not found for name: ${memberOption.value}`);
        continue;
      }
  
      const memberVotes = await fetchMemberVotesPromise(mep);
      await storeVotesInFirestorePromise(mep, memberVotes);
    }
  };

  const includeNamesOrig = [
    "Evin Incir",
    "Tomas Tobé",
    "Jessica Polfjärd",
    "Jörgen Warborn",
    "Arba Kokalari",
    "Peter Lundgren",
    "Johan Nissinen",
    "Charlie Weimers",
    "Alice Bah Kuhnke",
    "Pär Holmgren",
    "Jakop G. Dalunde",
    "Emma Wiesner",
    "Abir Al-Sahlani",
    "Sara Skyttedal",
    "David Lega",
    "Malin Björk",
    "Karin Karlsbro",
    "Johan Nissinen"
];

  const includeNames = [
    "David Lega",
];

  export const processMembersToDeleteAndWriteVotes = async (): Promise<void> => {
    for (const memberOption of memberNames) {
        
        if(!includeNames.includes(memberOption.label))
        continue;

        console.log(memberOption.label);
        
      const meps: IMember[] | null = await findMemberWithName(memberOption.value);
      const mep = meps && meps[0];
  
      if (!mep) {
        console.error(`MEP not found for name: ${memberOption.value}`);
        continue;
      }
  
      await deleteExistingVotes(mep); // Delete existing votes
      const memberVotes = await fetchMemberVotesPromise(mep);
      await storeVotesInFirestorePromise(mep, memberVotes); // Fetch and store new votes with is_featured property
      console.log("processing members is complete");
    }
  };


  const deleteExistingVotes = async (mep: IMember): Promise<void> => {
    const votesCollectionRef = collection(db, 'members', `${mep.id}`, 'votes');
    const votesSnapshot = await getDocs(votesCollectionRef);
  
    const batch = writeBatch(db); // Use batch for faster delete operations
  
    votesSnapshot.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });
  
    await batch.commit(); // Commit the batch
  };