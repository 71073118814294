import * as React from 'react';
import "./Footer.css"

interface IFooterProps {
}

const Footer: React.FunctionComponent<IFooterProps> = (props) => {

  return (
    <div className="footer-holder">
        <p>Data tacksamt lånat från howtheyvote.eu | Frågor eller synpunkter, kontakta <a href="https://www.linkedin.com/in/waye/">@Benjamin Waye</a></p>
    </div>
  );
};

export default Footer;
