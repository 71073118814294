import React, {  useState } from 'react';
import './App.css';
import { fetchVotesFromFirestoreWithLastDoc, findMemberWithName, processMembersToDeleteAndWriteVotes  } from './utils/firebase';
import Select from 'react-select';
import { memberNames } from './data/MemberNames';
import axios from "axios";
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore';
import { Chart } from "react-google-charts";


interface Vote {
  id: number;
  display_title: string;
  description: string;
  timestamp: string;
  position: "ABSTENTION" | "DID_NOT_VOTE" | "AGAINST" | "FOR";
  is_featured: boolean
}

interface IMember {
  id: number;
  first_name: string;
  last_name: string;
  photo_url: string;
  group: {
    code: string,
    label: string,
    short_label: string
  },
  email: string,
  twitter: string,
  facebook: string,
  statistics: IStatistics
}

interface IStatistics {
  statistics: {
    category: "FOR" | "ABSTENTION" | "AGAINST" | "DID_NOT_VOTE";
    count: number;
  }
}

interface IVoteDetails {
  id: number;
  display_title: string;
  description: string;
  timestamp: string;
  member_votes: IMember[];
  is_featured: boolean;
  facts: string;
  sharepic_url: string;
  reference: string;
}

const voteDetailsView = ({setShowTable, voteDetails}:any) => 
<div className="vote-details-container">
<button className="blue-button" onClick={() => setShowTable(true)}>Tillbaka</button>
  <div className="vote-details-view">
    <h2>{voteDetails?.display_title}</h2>
    <span>{new Date(voteDetails.timestamp).toDateString()} - {voteDetails.reference} - {voteDetails.description}</span>
    <div className="vote-details-facts" dangerouslySetInnerHTML={{__html: voteDetails?.facts}}/>
    <a target="_blank" rel="noreferrer" href={"https://howtheyvote.eu/votes/" + voteDetails.id}>Link to howtheyvote</a>
  </div>
</div>


const App: React.FC = () => {
  const [name, setName] = useState<string | {first_name: string, last_name: string}>('');
  const [votes, setVotes] = useState<Vote[]>([]);
  const [mep, setMep] = useState<IMember>();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [voteDetails, setVoteDetails] = useState<IVoteDetails | undefined>(undefined);
  const [showTable, setShowTable] = useState<boolean>(false)
  const [sortDateToggle, setSortDateToggle] = useState<boolean>(true)
  const [lastDoc, setLastDoc] = useState<QueryDocumentSnapshot<DocumentData> | null>(null);


  const handleSearch = async (forceName?:string|undefined|{first_name:string, last_name:string}) => {
    setIsLoading(true);
    //processMembersToDeleteAndWriteVotes().catch(console.error);
    setError(null);
    setVotes([]);
    setLastDoc(null);
  
    try {
      // Fetch mep with name
      const meps: IMember[] | null = await findMemberWithName(forceName ? forceName : name);
      const mep = meps && meps[0];
  
      if (!mep) {
        setError('Parlamentariker hittades ej');
        setIsLoading(false);
        return;
      }

      setMep(mep)

      // Fetch member votes from Firestore
      const { votes: memberVotes, lastDoc: fetchedLastDoc } = await fetchVotesFromFirestoreWithLastDoc(mep, true, 20); 
      setVotes(memberVotes);
      setLastDoc(fetchedLastDoc);
      
      if (memberVotes.length === 0) {
        // Fetch more votes if the initial fetch is empty
        const { votes: memberVotes, lastDoc: fetchedLastDoc } = await fetchVotesFromFirestoreWithLastDoc(mep, false, 20);
        setVotes(memberVotes);
        setLastDoc(fetchedLastDoc);
      }
      
      
    } catch (err) {
      setError('Failed to fetch data');
    } finally {
      setIsLoading(false);
      setShowTable(true)
    }
  };

  const getVoteDetailsForId = async (id: string) => {
    const response = await axios.get(`https://howtheyvote.eu/api/votes/${id}`);

    const voteDetails: IVoteDetails = response.data;

    setVoteDetails(voteDetails);
    setShowTable(false);
  }

  const sortVotesByDate = () => {
    const sortedVotes = [...votes].sort((a, b) => {
      return sortDateToggle
        ? Number(new Date(b.timestamp)) - Number(new Date(a.timestamp))
        : Number(new Date(a.timestamp)) - Number(new Date(b.timestamp));
    });
    setVotes(sortedVotes);
    setSortDateToggle(!sortDateToggle);
  };

  const getVotesForMember = (memberName:string | {first_name: string, last_name: string}) => {
    setName(memberName);
    handleSearch(memberName);
  }

  const fetchMoreVotes = async () => {
    if (!mep || !lastDoc) return;

    setIsLoading(true);
    try {
      const { votes: moreVotes, lastDoc: fetchedLastDoc } = await fetchVotesFromFirestoreWithLastDoc(mep, true, 20, lastDoc);
      setVotes(prevVotes => [...prevVotes, ...moreVotes]);
      setLastDoc(fetchedLastDoc);
      if (moreVotes.length === 0) {
        // Fetch more votes if the initial fetch is empty
        const { votes: moreVotes, lastDoc: fetchedLastDoc } = await fetchVotesFromFirestoreWithLastDoc(mep, false, 20, lastDoc);
        setVotes(prevVotes => [...prevVotes, ...moreVotes]);
        setLastDoc(fetchedLastDoc);
      }
    } catch (err) {
      setError('Failed to fetch more votes');
    } finally {
      setIsLoading(false);
    }
  };

  const getPositionTag = (position: any) => {
    switch (position) {
      case "FOR":
        return <span className="tag for">För</span>
      case "AGAINST":
        return <span className="tag against">Mot</span>
      case "ABSTENTION":
        return <span className="tag abstention">Blank röst</span>
      case "DID_NOT_VOTE":
        return <span className="tag did-not-vote">Frånvarande</span>
      default:
        break;
    }
  }

  const options = {
    title: "Fördelning av röster",
    chartArea: { width: "50%" },
    hAxis: {
      title: "Antal röster",
      minValue: 0,
    },
    legend: "none",
  };

  const getChartData = (mepStatistics: IStatistics) => {
    const categoryMapping: { [key: string]: string } = {
      FOR: 'För',
      AGAINST: 'Mot',
      ABSTENTION: 'Blank röst',
      DID_NOT_VOTE: 'Frånvarande'
    };
  
    const data: (string | number)[][] = [
      ["Category", "Count"],
    ];
  
    for (const [key, value] of Object.entries(mepStatistics)) {
      const renamedCategory = categoryMapping[key] || key;
      data.push([renamedCategory, value]);
    }    
  
    return data;
  }

  return (
    <div className="page-container">
      <div className="hero-container">
        <div className="hero-search-box">
        <h1>Upptäck hur dina EU parlamentariker röstar</h1>
        <Select
        className="basic-single"
        classNamePrefix="select"
        isLoading={isLoading}
        isDisabled={isLoading}
        isClearable={true}
        isSearchable={true}
        placeholder="Välj..."
        name="member name"
        options={memberNames}
        onChange={(e) => {e && setName(e.value)}}
      />      
      <button onClick={() => handleSearch()} disabled={isLoading}>Sök</button>
      {error && <div style={{ color: 'darkred' }}>{error}</div>}
        </div>
        
      </div>
      <div className="contents-container">
      {!name && <div className="member-links">
        <div>
        <h3>S</h3>
          <p onClick={() => getVotesForMember("Evin INCIR")}>Evin Incir</p>
        <h3>M</h3>
        <p onClick={() => getVotesForMember("Tomas TOBÉ")}>Tomas Tobé</p>
          <p onClick={() => getVotesForMember("Jessica POLFJÄRD")}>Jessica Polfjärd</p>
          <p onClick={() => getVotesForMember("Jörgen WARBORN")}>Jörgen Warborn</p>
          <p onClick={() => getVotesForMember("Arba KOKALARI")}>Arba Kokalari</p>
        <h3>SD</h3>
        <p onClick={() => getVotesForMember("Peter LUNDGREN")}>Peter Lundgren</p>
        <p onClick={() => getVotesForMember("Johan NISSINEN")}>Johan Nissinen</p>
        <p onClick={() => getVotesForMember("Charlie WEIMERS")}>Charlie Weimers</p>
        </div>
        <div>
        <h3>MP</h3>
          <p onClick={() => getVotesForMember("Alice KUHNKE")}>Alice Bah Kuhnke</p>
          <p onClick={() => getVotesForMember("Pär HOLMGREN")}>Pär Holmgren</p>
          <p onClick={() => getVotesForMember({first_name: "Jakop G.", last_name: "DALUNDE"})}>Jakop G. Dalunde</p>
        <h3>C</h3>
          <p onClick={() => getVotesForMember("Emma WIESNER")}>Emma Wiesner</p>
          <p onClick={() => getVotesForMember("Abir AL-SAHLANI")}>Abir Al-Sahlani</p>
        <h3>KD</h3>
          <p onClick={() => getVotesForMember("Sara SKYTTEDAL")}>Sara Skyttedal</p>
          <p onClick={() => getVotesForMember("David LEGA")}>David Lega</p>
        <h3>V</h3>
          <p onClick={() => getVotesForMember("Malin BJÖRK")}>Malin Björk</p>
        <h3>L</h3>
          <p onClick={() => getVotesForMember("Karin KARLSBRO")}>Karin Karlsbro</p>
          </div>
      </div>}
      {mep && <div className="mep-container"><div className="mep-details">
      <div className="avatar">
        <img src={"https://howtheyvote.eu" + mep?.photo_url} alt={mep?.first_name + " " + mep?.last_name}/>
        </div>
        <div className="mep-details__info">
          <p>{mep?.first_name + " " + mep?.last_name}</p>
          <p className="group-tag">{mep?.group.code}</p>
          <a href={"mailto:" + mep?.email}>{mep?.email}</a><br/>
          {mep?.twitter && <div><a href={mep?.twitter}>twitter</a><br/></div>}
          {mep?.twitter && <div><a href={mep?.facebook}>facebook</a><br/></div>}
        </div>
      </div><div>
      <Chart
      chartType="BarChart"
      width="100%"
      height="200px"
      data={getChartData(mep?.statistics)}
      options={options}
      key={mep?.id}
    />
        </div></div>}
      {showTable ? <div className="table-container"><table>
        <tr>
          <th onClick={sortVotesByDate}>Datum</th>
          <th>Titel</th>
          <th>Röst</th>
          <th>Nyhetsvärde</th>
          <th></th>
        </tr>
        {votes.map((vote) => (
          <tr key={vote.id}>
            <td className="table-date">{new Date(vote.timestamp).toDateString()}</td>
            <td>{vote.display_title}</td>
            <td className="position">{getPositionTag(vote.position)}</td>
            <td>{vote.is_featured ? <span className="tag">omnämnd</span> : ""}</td>
            <td><button className="blue-button"onClick={() => getVoteDetailsForId(vote.id.toString())}>mer info</button></td>
          </tr>
        ))}
      </table><span className="table-more-votes" onClick={fetchMoreVotes}>...hämta fler röster</span></div> : voteDetails && voteDetailsView({setShowTable, voteDetails})}
      </div>
    </div>
  );
};

export default App;
