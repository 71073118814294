export interface memberOption {
    readonly value: string | {first_name: string, last_name: string};
    readonly label: string;
    readonly color?: string;
    readonly isFixed?: boolean;
    readonly isDisabled?: boolean;
  }

export const memberNames: readonly memberOption[] = [
    {
       value: "Paolo BORCHIA",
       label: "Paolo Borchia"
    },
    {
       value: "Niels FUGLSANG",
       label: "Niels Fuglsang"
    },
    {
       value: "Terry REINTKE",
       label: "Terry Reintke"
    },
    {
       value: "Daniel FREUND",
       label: "Daniel Freund"
    },
    {
       value: "Andrey NOVAKOV",
       label: "Andrey Novakov"
    },
    {
       value: "Petri SARVAMAA",
       label: "Petri Sarvamaa"
    },
    {
       value: "Tonino PICULA",
       label: "Tonino Picula"
    },
    {
       value: "Romana JERKOVIĆ",
       label: "Romana Jerković"
    },
    {
       value: "Biljana BORZAN",
       label: "Biljana Borzan"
    },
    {
       value: "Nils TORVALDS",
       label: "Nils Torvalds"
    },
    {
       value: "Roberta METSOLA",
       label: "Roberta Metsola"
    },
    {
       value: "Ondřej KOVAŘÍK",
       label: "Ondřej Kovařík"
    },
    {
       value: "Martina MICHELS",
       label: "Martina Michels"
    },
    {
       value: "Alicia HOMS GINEL",
       label: "Alicia Homs ginel"
    },
    {
       value: "Charlie WEIMERS",
       label: "Charlie Weimers"
    },
    {
       value: "Balázs HIDVÉGHI",
       label: "Balázs Hidvéghi"
    },
    {
       value: "Costas MAVRIDES",
       label: "Costas Mavrides"
    },
    {
       value: "Demetris PAPADAKIS",
       label: "Demetris Papadakis"
    },
    {
       value: "Pascal DURAND",
       label: "Pascal Durand"
    },
    {
       value: "Andrus ANSIP",
       label: "Andrus Ansip"
    },
    {
       value: "Jana TOOM",
       label: "Jana Toom"
    },
    {
       value: "Luděk NIEDERMAYER",
       label: "Luděk Niedermayer"
    },
    {
       value: "Stanislav POLČÁK",
       label: "Stanislav Polčák"
    },
    {
       value: "István UJHELYI",
       label: "István Ujhelyi"
    },
    {
       value: "Dita CHARANZOVÁ",
       label: "Dita Charanzová"
    },
    {
       value: "Martina DLABAJOVÁ",
       label: "Martina Dlabajová"
    },
    {
       value: "Michaela ŠOJDROVÁ",
       label: "Michaela Šojdrová"
    },
    {
       value: "Andrea BOCSKOR",
       label: "Andrea Bocskor"
    },
    {
       value: "Tomáš ZDECHOVSKÝ",
       label: "Tomáš Zdechovský"
    },
    {
       value: "Andor DELI",
       label: "Andor Deli"
    },
    {
       value: "György HÖLVÉNYI",
       label: "György Hölvényi"
    },
    {
       value: "Csaba MOLNÁR",
       label: "Csaba Molnár"
    },
    {
       value: "Henna VIRKKUNEN",
       label: "Henna Virkkunen"
    },
    {
       value: "Miapetra KUMPULA-NATRI",
       label: "Miapetra Kumpula-natri"
    },
    {
       value: "Gilles LEBRETON",
       label: "Gilles Lebreton"
    },
    {
       value: "Carlos ZORRINHO",
       label: "Carlos Zorrinho"
    },
    {
       value: "Pedro SILVA PEREIRA",
       label: "Pedro Silva pereira"
    },
    {
       value: "Nicolas BAY",
       label: "Nicolas Bay"
    },
    {
       value: "Petras AUŠTREVIČIUS",
       label: "Petras Auštrevičius"
    },
    {
       value: "Jean-François JALKH",
       label: "Jean-françois Jalkh"
    },
    {
       value: "Dominique BILDE",
       label: "Dominique Bilde"
    },
    {
       value: "Tiziana BEGHIN",
       label: "Tiziana Beghin"
    },
    {
       value: "Marco ZANNI",
       label: "Marco Zanni"
    },
    {
       value: "Alfred SANT",
       label: "Alfred Sant"
    },
    {
       value: "Dan NICA",
       label: "Dan Nica"
    },
    {
       value: "Maria GRAPINI",
       label: "Maria Grapini"
    },
    {
       value: "Alessandra MORETTI",
       label: "Alessandra Moretti"
    },
    {
       value: "Siegfried MUREŞAN",
       label: "Siegfried Mureşan"
    },
    {
       value: "David McALLISTER",
       label: "David Mcallister"
    },
    {
       value: "Jens GIESEKE",
       label: "Jens Gieseke"
    },
    {
       value: "Norbert LINS",
       label: "Norbert Lins"
    },
    {
       value: "Fabio Massimo CASTALDO",
       label: "Fabio massimo Castaldo"
    },
    {
       value: "Nicola DANTI",
       label: "Nicola Danti"
    },
    {
       value: "Dietmar KÖSTER",
       label: "Dietmar Köster"
    },
    {
       value: "Fulvio MARTUSCIELLO",
       label: "Fulvio Martusciello"
    },
    {
       value: "Isabella ADINOLFI",
       label: "Isabella Adinolfi"
    },
    {
       value: "Laura FERRARA",
       label: "Laura Ferrara"
    },
    {
       value: "Martin SONNEBORN",
       label: "Martin Sonneborn"
    },
    {
       value: "Rosa D'AMATO",
       label: "Rosa D'amato"
    },
    {
       value: "Maria NOICHL",
       label: "Maria Noichl"
    },
    {
       value: "Joachim SCHUSTER",
       label: "Joachim Schuster"
    },
    {
       value: "Piernicola PEDICINI",
       label: "Piernicola Pedicini"
    },
    {
       value: "Pina PICIERNO",
       label: "Pina Picierno"
    },
    {
       value: "Emil RADEV",
       label: "Emil Radev"
    },
    {
       value: "Sergei STANISHEV",
       label: "Sergei Stanishev"
    },
    {
       value: "Ignazio CORRAO",
       label: "Ignazio Corrao"
    },
    {
       value: "Caterina CHINNICI",
       label: "Caterina Chinnici"
    },
    {
       value: "Ulrike MÜLLER",
       label: "Ulrike Müller"
    },
    {
       value: "Ilhan KYUCHYUK",
       label: "Ilhan Kyuchyuk"
    },
    {
       value: "Brando BENIFEI",
       label: "Brando Benifei"
    },
    {
       value: "Morten PETERSEN",
       label: "Morten Petersen"
    },
    {
       value: "Angel DZHAMBAZKI",
       label: "Angel Dzhambazki"
    },
    {
       value: "Anders VISTISEN",
       label: "Anders Vistisen"
    },
    {
       value: "Jadwiga WIŚNIEWSKA",
       label: "Jadwiga Wiśniewska"
    },
    {
       value: "Kosma ZŁOTOWSKI",
       label: "Kosma Złotowski"
    },
    {
       value: "Karol KARSKI",
       label: "Karol Karski"
    },
    {
       value: "Zdzisław KRASNODĘBSKI",
       label: "Zdzisław Krasnodębski"
    },
    {
       value: "Ivan ŠTEFANEC",
       label: "Ivan Štefanec"
    },
    {
       value: "Monika VANA",
       label: "Monika Vana"
    },
    {
       value: "Maria ARENA",
       label: "Maria Arena"
    },
    {
       value: "Gerolf ANNEMANS",
       label: "Gerolf Annemans"
    },
    {
       value: "Luke Ming FLANAGAN",
       label: "Luke ming Flanagan"
    },
    {
       value: "Deirdre CLUNE",
       label: "Deirdre Clune"
    },
    {
       value: "Malin BJÖRK",
       label: "Malin Björk"
    },
    {
       value: "Peter LUNDGREN",
       label: "Peter Lundgren"
    },
    {
       value: "Harald VILIMSKY",
       label: "Harald Vilimsky"
    },
    {
       value: "Franc BOGOVIČ",
       label: "Franc Bogovič"
    },
    {
       value: "Javier NART",
       label: "Javier Nart"
    },
    {
       value: "Daniel BUDA",
       label: "Daniel Buda"
    },
    {
       value: "Paul TANG",
       label: "Paul Tang"
    },
    {
       value: "Agnes JONGERIUS",
       label: "Agnes Jongerius"
    },
    {
       value: "Anja HAZEKAMP",
       label: "Anja Hazekamp"
    },
    {
       value: "Marcel de GRAAFF",
       label: "Marcel De graaff"
    },
    {
       value: "Annie SCHREIJER-PIERIK",
       label: "Annie Schreijer-pierik"
    },
    {
       value: "Maite PAGAZAURTUNDÚA",
       label: "Maite Pagazaurtundúa"
    },
    {
       value: "Javi LÓPEZ",
       label: "Javi López"
    },
    {
       value: "Inma RODRÍGUEZ-PIÑERO",
       label: "Inma Rodríguez-piñero"
    },
    {
       value: "Clara AGUILERA",
       label: "Clara Aguilera"
    },
    {
       value: "Jonás FERNÁNDEZ",
       label: "Jonás Fernández"
    },
    {
       value: "Georgios KYRTSOS",
       label: "Georgios Kyrtsos"
    },
    {
       value: "Maria SPYRAKI",
       label: "Maria Spyraki"
    },
    {
       value: "Elissavet VOZEMBERG-VRIONIDI",
       label: "Elissavet Vozemberg-vrionidi"
    },
    {
       value: "Theodoros ZAGORAKIS",
       label: "Theodoros Zagorakis"
    },
    {
       value: "Manolis KEFALOGIANNIS",
       label: "Manolis Kefalogiannis"
    },
    {
       value: "Kostas PAPADAKIS",
       label: "Kostas Papadakis"
    },
    {
       value: "Romana TOMC",
       label: "Romana Tomc"
    },
    {
       value: "Johan VAN OVERTVELDT",
       label: "Johan Van overtveldt"
    },
    {
       value: "Eva KAILI",
       label: "Eva Kaili"
    },
    {
       value: "Iskra MIHAYLOVA",
       label: "Iskra Mihaylova"
    },
    {
       value: "Bronis ROPĖ",
       label: "Bronis Ropė"
    },
    {
       value: "Marco ZULLO",
       label: "Marco Zullo"
    },
    {
       value: "Massimiliano SALINI",
       label: "Massimiliano Salini"
    },
    {
       value: "Jiří POSPÍŠIL",
       label: "Jiří Pospíšil"
    },
    {
       value: "Jordi CAÑAS",
       label: "Jordi Cañas"
    },
    {
       value: "Domènec RUIZ DEVESA",
       label: "Domènec Ruiz devesa"
    },
    {
       value: "Markus BUCHHEIT",
       label: "Markus Buchheit"
    },
    {
       value: "Urmas PAET",
       label: "Urmas Paet"
    },
    {
       value: "Tom VANDENKENDELAERE",
       label: "Tom Vandenkendelaere"
    },
    {
       value: "Hilde VAUTMANS",
       label: "Hilde Vautmans"
    },
    {
       value: "Dace MELBĀRDE",
       label: "Dace Melbārde"
    },
    {
       value: "Stelios KOULOGLOU",
       label: "Stelios Kouloglou"
    },
    {
       value: "Miguel URBÁN CRESPO",
       label: "Miguel Urbán crespo"
    },
    {
       value: "Jordan BARDELLA",
       label: "Jordan Bardella"
    },
    {
       value: "Gunnar BECK",
       label: "Gunnar Beck"
    },
    {
       value: "Pirkko RUOHONEN-LERNER",
       label: "Pirkko Ruohonen-lerner"
    },
    {
       value: "Ramona STRUGARIU",
       label: "Ramona Strugariu"
    },
    {
       value: "Valérie HAYER",
       label: "Valérie Hayer"
    },
    {
       value: "João PIMENTA LOPES",
       label: "João Pimenta lopes"
    },
    {
       value: "Annika BRUNA",
       label: "Annika Bruna"
    },
    {
       value: {first_name:"Jakop G.", last_name:"DALUNDE"},
       label: "Jakop G. Dalunde"
    },
    {
       value: "Angelo CIOCCA",
       label: "Angelo Ciocca"
    },
    {
       value: "Alexandra GEESE",
       label: "Alexandra Geese"
    },
    {
       value: "Željana ZOVKO",
       label: "Željana Zovko"
    },
    {
       value: "Tiemo WÖLKEN",
       label: "Tiemo Wölken"
    },
    {
       value: "Tom VANDENDRIESSCHE",
       label: "Tom Vandendriessche"
    },
    {
       value: "Jordi SOLÉ",
       label: "Jordi Solé"
    },
    {
       value: "Marc BOTENGA",
       label: "Marc Botenga"
    },
    {
       value: "Dennis RADTKE",
       label: "Dennis Radtke"
    },
    {
       value: "France JAMET",
       label: "France Jamet"
    },
    {
       value: "Carlos COELHO",
       label: "Carlos Coelho"
    },
    {
       value: "Asim ADEMOV",
       label: "Asim Ademov"
    },
    {
       value: "Thomas WAITZ",
       label: "Thomas Waitz"
    },
    {
       value: "Martin SCHIRDEWAN",
       label: "Martin Schirdewan"
    },
    {
       value: "Jörg MEUTHEN",
       label: "Jörg Meuthen"
    },
    {
       value: "Caroline NAGTEGAAL",
       label: "Caroline Nagtegaal"
    },
    {
       value: "Lukas MANDL",
       label: "Lukas Mandl"
    },
    {
       value: "Geoffroy DIDIER",
       label: "Geoffroy Didier"
    },
    {
       value: "Bernd LANGE",
       label: "Bernd Lange"
    },
    {
       value: "Elsi KATAINEN",
       label: "Elsi Katainen"
    },
    {
       value: "Markus FERBER",
       label: "Markus Ferber"
    },
    {
       value: "Giuseppe FERRANDINO",
       label: "Giuseppe Ferrandino"
    },
    {
       value: "Danilo Oscar LANCINI",
       label: "Danilo oscar Lancini"
    },
    {
       value: "Peter LIESE",
       label: "Peter Liese"
    },
    {
       value: "Romeo FRANZ",
       label: "Romeo Franz"
    },
    {
       value: "Tilly METZ",
       label: "Tilly Metz"
    },
    {
       value: "Sara SKYTTEDAL",
       label: "Sara Skyttedal"
    },
    {
       value: "Heléne FRITZON",
       label: "Heléne Fritzon"
    },
    {
       value: "Evin INCIR",
       label: "Evin Incir"
    },
    {
       value: "David LEGA",
       label: "David Lega"
    },
    {
       value: "Josianne CUTAJAR",
       label: "Josianne Cutajar"
    },
    {
       value: "Alice KUHNKE",
       label: "Alice Kuhnke"
    },
    {
       value: "Pär HOLMGREN",
       label: "Pär Holmgren"
    },
    {
       value: "Abir AL-SAHLANI",
       label: "Abir Al-sahlani"
    },
    {
       value: "Karin KARLSBRO",
       label: "Karin Karlsbro"
    },
    {
       value: "Tomas TOBÉ",
       label: "Tomas Tobé"
    },
    {
       value: "Alex AGIUS SALIBA",
       label: "Alex Agius saliba"
    },
    {
       value: "Jessica POLFJÄRD",
       label: "Jessica Polfjärd"
    },
    {
       value: "Jörgen WARBORN",
       label: "Jörgen Warborn"
    },
    {
       value: "Arba KOKALARI",
       label: "Arba Kokalari"
    },
    {
       value: "Hildegard BENTELE",
       label: "Hildegard Bentele"
    },
    {
       value: "Stefan BERGER",
       label: "Stefan Berger"
    },
    {
       value: "Niclas HERBST",
       label: "Niclas Herbst"
    },
    {
       value: "Karlo RESSLER",
       label: "Karlo Ressler"
    },
    {
       value: "Loucas FOURLAS",
       label: "Loucas Fourlas"
    },
    {
       value: "Niyazi KIZILYÜREK",
       label: "Niyazi Kizilyürek"
    },
    {
       value: "Giorgos GEORGIOU",
       label: "Giorgos Georgiou"
    },
    {
       value: "Tomislav SOKOL",
       label: "Tomislav Sokol"
    },
    {
       value: "Monica SEMEDO",
       label: "Monica Semedo"
    },
    {
       value: "Valter FLEGO",
       label: "Valter Flego"
    },
    {
       value: "Isabel WISELER-LIMA",
       label: "Isabel Wiseler-lima"
    },
    {
       value: "Christine SCHNEIDER",
       label: "Christine Schneider"
    },
    {
       value: "Nico SEMSROTT",
       label: "Nico Semsrott"
    },
    {
       value: "Ralf SEEKATZ",
       label: "Ralf Seekatz"
    },
    {
       value: "Sven SIMON",
       label: "Sven Simon"
    },
    {
       value: "Marlene MORTLER",
       label: "Marlene Mortler"
    },
    {
       value: "Marion WALSMANN",
       label: "Marion Walsmann"
    },
    {
       value: "Engin EROGLU",
       label: "Engin Eroglu"
    },
    {
       value: "Patrick BREYER",
       label: "Patrick Breyer"
    },
    {
       value: "Jan-Christoph OETJEN",
       label: "Jan-christoph Oetjen"
    },
    {
       value: "Katarina BARLEY",
       label: "Katarina Barley"
    },
    {
       value: "Martin BUSCHMANN",
       label: "Martin Buschmann"
    },
    {
       value: "Gabriele BISCHOFF",
       label: "Gabriele Bischoff"
    },
    {
       value: "Mislav KOLAKUŠIĆ",
       label: "Mislav Kolakušić"
    },
    {
       value: "Damian BOESELAGER",
       label: "Damian Boeselager"
    },
    {
       value: "Delara BURKHARDT",
       label: "Delara Burkhardt"
    },
    {
       value: "Predrag Fred MATIĆ",
       label: "Predrag fred Matić"
    },
    {
       value: "Andreas GLÜCK",
       label: "Andreas Glück"
    },
    {
       value: "Svenja HAHN",
       label: "Svenja Hahn"
    },
    {
       value: "Moritz KÖRNER",
       label: "Moritz Körner"
    },
    {
       value: "Milan BRGLEZ",
       label: "Milan Brglez"
    },
    {
       value: "Irena JOVEVA",
       label: "Irena Joveva"
    },
    {
       value: "Rasmus ANDRESEN",
       label: "Rasmus Andresen"
    },
    {
       value: "Michael BLOSS",
       label: "Michael Bloss"
    },
    {
       value: "Viola VON CRAMON-TAUBADEL",
       label: "Viola Von cramon-taubadel"
    },
    {
       value: "Klemen GROŠELJ",
       label: "Klemen Grošelj"
    },
    {
       value: "Anna DEPARNAY-GRUNENBERG",
       label: "Anna Deparnay-grunenberg"
    },
    {
       value: "Cindy FRANSSEN",
       label: "Cindy Franssen"
    },
    {
       value: "Henrike HAHN",
       label: "Henrike Hahn"
    },
    {
       value: "Benoît LUTGEN",
       label: "Benoît Lutgen"
    },
    {
       value: "Pierrette HERZBERGER-FOFANA",
       label: "Pierrette Herzberger-fofana"
    },
    {
       value: "Sergey LAGODINSKY",
       label: "Sergey Lagodinsky"
    },
    {
       value: "Katrin LANGENSIEPEN",
       label: "Katrin Langensiepen"
    },
    {
       value: "Erik MARQUARDT",
       label: "Erik Marquardt"
    },
    {
       value: "Olivier CHASTEL",
       label: "Olivier Chastel"
    },
    {
       value: "Hannah NEUMANN",
       label: "Hannah Neumann"
    },
    {
       value: "Niklas NIENASS",
       label: "Niklas Nienass"
    },
    {
       value: "Jutta PAULUS",
       label: "Jutta Paulus"
    },
    {
       value: "Geert BOURGEOIS",
       label: "Geert Bourgeois"
    },
    {
       value: "Özlem DEMIREL",
       label: "Özlem Demirel"
    },
    {
       value: "Assita KANKO",
       label: "Assita Kanko"
    },
    {
       value: "Saskia BRICMONT",
       label: "Saskia Bricmont"
    },
    {
       value: "Filip DE MAN",
       label: "Filip De man"
    },
    {
       value: "Christian DOLESCHAL",
       label: "Christian Doleschal"
    },
    {
       value: "Christine ANDERSON",
       label: "Christine Anderson"
    },
    {
       value: "Lars Patrick BERG",
       label: "Lars patrick Berg"
    },
    {
       value: "Nicolaus FEST",
       label: "Nicolaus Fest"
    },
    {
       value: "Maximilian KRAH",
       label: "Maximilian Krah"
    },
    {
       value: "Joachim KUHS",
       label: "Joachim Kuhs"
    },
    {
       value: "Sylvia LIMMER",
       label: "Sylvia Limmer"
    },
    {
       value: "Guido REIL",
       label: "Guido Reil"
    },
    {
       value: "Bernhard ZIMNIOK",
       label: "Bernhard Zimniok"
    },
    {
       value: "Magdalena ADAMOWICZ",
       label: "Magdalena Adamowicz"
    },
    {
       value: "Marina KALJURAND",
       label: "Marina Kaljurand"
    },
    {
       value: "Jaak MADISON",
       label: "Jaak Madison"
    },
    {
       value: "Nathalie LOISEAU",
       label: "Nathalie Loiseau"
    },
    {
       value: "Marek Paweł BALT",
       label: "Marek paweł Balt"
    },
    {
       value: "Marek BELKA",
       label: "Marek Belka"
    },
    {
       value: "Sven MIKSER",
       label: "Sven Mikser"
    },
    {
       value: "Robert BIEDROŃ",
       label: "Robert Biedroń"
    },
    {
       value: "Mounir SATOURI",
       label: "Mounir Satouri"
    },
    {
       value: "Joachim Stanisław BRUDZIŃSKI",
       label: "Joachim stanisław Brudziński"
    },
    {
       value: "Marie-Pierre VEDRENNE",
       label: "Marie-pierre Vedrenne"
    },
    {
       value: "David CORMAND",
       label: "David Cormand"
    },
    {
       value: "Jérémy DECERLE",
       label: "Jérémy Decerle"
    },
    {
       value: "Catherine CHABAUD",
       label: "Catherine Chabaud"
    },
    {
       value: "Caroline ROOSE",
       label: "Caroline Roose"
    },
    {
       value: "Włodzimierz CIMOSZEWICZ",
       label: "Włodzimierz Cimoszewicz"
    },
    {
       value: "Jarosław DUDA",
       label: "Jarosław Duda"
    },
    {
       value: "Salima YENBOU",
       label: "Salima Yenbou"
    },
    {
       value: "Benoît BITEAU",
       label: "Benoît Biteau"
    },
    {
       value: "Tomasz FRANKOWSKI",
       label: "Tomasz Frankowski"
    },
    {
       value: "Andrzej HALICKI",
       label: "Andrzej Halicki"
    },
    {
       value: "Pernille WEISS",
       label: "Pernille Weiss"
    },
    {
       value: "Patryk JAKI",
       label: "Patryk Jaki"
    },
    {
       value: "Adam JARUBAS",
       label: "Adam Jarubas"
    },
    {
       value: "Krzysztof JURGIEL",
       label: "Krzysztof Jurgiel"
    },
    {
       value: "Beata KEMPA",
       label: "Beata Kempa"
    },
    {
       value: "Izabela-Helena KLOC",
       label: "Izabela-helena Kloc"
    },
    {
       value: "Radka MAXOVÁ",
       label: "Radka Maxová"
    },
    {
       value: "Łukasz KOHUT",
       label: "Łukasz Kohut"
    },
    {
       value: "Ewa KOPACZ",
       label: "Ewa Kopacz"
    },
    {
       value: "Martin HLAVÁČEK",
       label: "Martin Hlaváček"
    },
    {
       value: "Anne-Sophie PELLETIER",
       label: "Anne-sophie Pelletier"
    },
    {
       value: "Ondřej KNOTEK",
       label: "Ondřej Knotek"
    },
    {
       value: "Leila CHAIBI",
       label: "Leila Chaibi"
    },
    {
       value: "Joanna KOPCIŃSKA",
       label: "Joanna Kopcińska"
    },
    {
       value: "Gwendoline DELBOS-CORFIELD",
       label: "Gwendoline Delbos-corfield"
    },
    {
       value: "Elżbieta KRUK",
       label: "Elżbieta Kruk"
    },
    {
       value: "Manon AUBRY",
       label: "Manon Aubry"
    },
    {
       value: "François-Xavier BELLAMY",
       label: "François-xavier Bellamy"
    },
    {
       value: "Nathalie COLIN-OESTERLÉ",
       label: "Nathalie Colin-oesterlé"
    },
    {
       value: "Alexandr VONDRA",
       label: "Alexandr Vondra"
    },
    {
       value: "Beata MAZUREK",
       label: "Beata Mazurek"
    },
    {
       value: "Mikuláš PEKSA",
       label: "Mikuláš Peksa"
    },
    {
       value: "Leszek MILLER",
       label: "Leszek Miller"
    },
    {
       value: "Andżelika Anna MOŻDŻANOWSKA",
       label: "Andżelika anna Możdżanowska"
    },
    {
       value: "Janina OCHOJSKA",
       label: "Janina Ochojska"
    },
    {
       value: "Bernard GUETTA",
       label: "Bernard Guetta"
    },
    {
       value: "Elżbieta RAFALSKA",
       label: "Elżbieta Rafalska"
    },
    {
       value: "Bogdan RZOŃCA",
       label: "Bogdan Rzońca"
    },
    {
       value: "Marcel KOLAJA",
       label: "Marcel Kolaja"
    },
    {
       value: "Irène TOLLERET",
       label: "Irène Tolleret"
    },
    {
       value: "Markéta GREGOROVÁ",
       label: "Markéta Gregorová"
    },
    {
       value: "Sylwia SPUREK",
       label: "Sylwia Spurek"
    },
    {
       value: "Stéphane BIJOUX",
       label: "Stéphane Bijoux"
    },
    {
       value: "Veronika VRECIONOVÁ",
       label: "Veronika Vrecionová"
    },
    {
       value: "Beata SZYDŁO",
       label: "Beata Szydło"
    },
    {
       value: "Ivan DAVID",
       label: "Ivan David"
    },
    {
       value: "Christophe GRUDLER",
       label: "Christophe Grudler"
    },
    {
       value: "Asger CHRISTENSEN",
       label: "Asger Christensen"
    },
    {
       value: "Grzegorz TOBISZOWSKI",
       label: "Grzegorz Tobiszowski"
    },
    {
       value: "Mauri PEKKARINEN",
       label: "Mauri Pekkarinen"
    },
    {
       value: "Hynek BLAŠKO",
       label: "Hynek Blaško"
    },
    {
       value: "Witold Jan WASZCZYKOWSKI",
       label: "Witold jan Waszczykowski"
    },
    {
       value: "Karen MELCHIOR",
       label: "Karen Melchior"
    },
    {
       value: "Nikolaj VILLUMSEN",
       label: "Nikolaj Villumsen"
    },
    {
       value: "Anna ZALEWSKA",
       label: "Anna Zalewska"
    },
    {
       value: "Kira Marie PETER-HANSEN",
       label: "Kira marie Peter-hansen"
    },
    {
       value: "Damien CARÊME",
       label: "Damien Carême"
    },
    {
       value: "Sylvie BRUNET",
       label: "Sylvie Brunet"
    },
    {
       value: "Gilles BOYER",
       label: "Gilles Boyer"
    },
    {
       value: "Silvia SARDONE",
       label: "Silvia Sardone"
    },
    {
       value: "Klára DOBREV",
       label: "Klára Dobrev"
    },
    {
       value: "Isabella TOVAGLIERI",
       label: "Isabella Tovaglieri"
    },
    {
       value: "Stéphanie YON-COURTIN",
       label: "Stéphanie Yon-courtin"
    },
    {
       value: "Gianna GANCIA",
       label: "Gianna Gancia"
    },
    {
       value: "Sándor RÓNAI",
       label: "Sándor Rónai"
    },
    {
       value: "Pierre KARLESKIND",
       label: "Pierre Karleskind"
    },
    {
       value: "Stefania ZAMBELLI",
       label: "Stefania Zambelli"
    },
    {
       value: "Attila ARA-KOVÁCS",
       label: "Attila Ara-kovács"
    },
    {
       value: "Katalin CSEH",
       label: "Katalin Cseh"
    },
    {
       value: "Laurence FARRENG",
       label: "Laurence Farreng"
    },
    {
       value: "Giuliano PISAPIA",
       label: "Giuliano Pisapia"
    },
    {
       value: "Irene TINAGLI",
       label: "Irene Tinagli"
    },
    {
       value: "Anna Júlia DONÁTH",
       label: "Anna júlia Donáth"
    },
    {
       value: "Teuvo HAKKARAINEN",
       label: "Teuvo Hakkarainen"
    },
    {
       value: "Javier ZARZALEJOS",
       label: "Javier Zarzalejos"
    },
    {
       value: "Pietro FIOCCHI",
       label: "Pietro Fiocchi"
    },
    {
       value: "Gianantonio DA RE",
       label: "Gianantonio Da re"
    },
    {
       value: "Alessandra BASSO",
       label: "Alessandra Basso"
    },
    {
       value: "Elena LIZZI",
       label: "Elena Lizzi"
    },
    {
       value: "Jérôme RIVIÈRE",
       label: "Jérôme Rivière"
    },
    {
       value: "Rosanna CONTE",
       label: "Rosanna Conte"
    },
    {
       value: "Elisabetta GUALMINI",
       label: "Elisabetta Gualmini"
    },
    {
       value: "Sabrina PIGNEDOLI",
       label: "Sabrina Pignedoli"
    },
    {
       value: "Juan Ignacio ZOIDO ÁLVAREZ",
       label: "Juan ignacio Zoido álvarez"
    },
    {
       value: "Thierry MARIANI",
       label: "Thierry Mariani"
    },
    {
       value: "Ivars IJABS",
       label: "Ivars Ijabs"
    },
    {
       value: "Hervé JUVIN",
       label: "Hervé Juvin"
    },
    {
       value: "Virginie JORON",
       label: "Virginie Joron"
    },
    {
       value: "Jean-Paul GARRAUD",
       label: "Jean-paul Garraud"
    },
    {
       value: "Maxette PIRBAKAS",
       label: "Maxette Pirbakas"
    },
    {
       value: "Dacian CIOLOŞ",
       label: "Dacian Cioloş"
    },
    {
       value: "Claudia GAMON",
       label: "Claudia Gamon"
    },
    {
       value: "Pedro MARQUES",
       label: "Pedro Marques"
    },
    {
       value: "Maria-Manuel LEITÃO-MARQUES",
       label: "Maria-manuel Leitão-marques"
    },
    {
       value: "Ioan-Rareş BOGDAN",
       label: "Ioan-rareş Bogdan"
    },
    {
       value: "Vasile BLAGA",
       label: "Vasile Blaga"
    },
    {
       value: "Margarida MARQUES",
       label: "Margarida Marques"
    },
    {
       value: "Sara CERDAS",
       label: "Sara Cerdas"
    },
    {
       value: "Francisco GUERREIRO",
       label: "Francisco Guerreiro"
    },
    {
       value: "Mircea-Gheorghe HAVA",
       label: "Mircea-gheorghe Hava"
    },
    {
       value: "Dan-Ştefan MOTREANU",
       label: "Dan-ştefan Motreanu"
    },
    {
       value: "Alexander BERNHUBER",
       label: "Alexander Bernhuber"
    },
    {
       value: "Gheorghe FALCĂ",
       label: "Gheorghe Falcă"
    },
    {
       value: "Isabel SANTOS",
       label: "Isabel Santos"
    },
    {
       value: "Carmen AVRAM",
       label: "Carmen Avram"
    },
    {
       value: "Angelika WINZIG",
       label: "Angelika Winzig"
    },
    {
       value: "Claudiu MANDA",
       label: "Claudiu Manda"
    },
    {
       value: "Ciarán CUFFE",
       label: "Ciarán Cuffe"
    },
    {
       value: "Cristian TERHEŞ",
       label: "Cristian Terheş"
    },
    {
       value: "Tudor CIUHODARU",
       label: "Tudor Ciuhodaru"
    },
    {
       value: "Adrian-Dragoş BENEA",
       label: "Adrian-dragoş Benea"
    },
    {
       value: "Vangelis MEIMARAKIS",
       label: "Vangelis Meimarakis"
    },
    {
       value: "Dragoş PÎSLARU",
       label: "Dragoş Pîslaru"
    },
    {
       value: "Dragoş TUDORACHE",
       label: "Dragoş Tudorache"
    },
    {
       value: "Barbara THALER",
       label: "Barbara Thaler"
    },
    {
       value: "Vlad-Marius BOTOŞ",
       label: "Vlad-marius Botoş"
    },
    {
       value: "Andreas SCHIEDER",
       label: "Andreas Schieder"
    },
    {
       value: "Traian BĂSESCU",
       label: "Traian Băsescu"
    },
    {
       value: "Eugen TOMAC",
       label: "Eugen Tomac"
    },
    {
       value: "Sarah WIENER",
       label: "Sarah Wiener"
    },
    {
       value: "Günther SIDL",
       label: "Günther Sidl"
    },
    {
       value: "Isabel BENJUMEA BENJUMEA",
       label: "Isabel Benjumea benjumea"
    },
    {
       value: "Aurélia BEIGNEUX",
       label: "Aurélia Beigneux"
    },
    {
       value: "Hannes HEIDE",
       label: "Hannes Heide"
    },
    {
       value: "Gilbert COLLARD",
       label: "Gilbert Collard"
    },
    {
       value: "Philippe OLIVIER",
       label: "Philippe Olivier"
    },
    {
       value: "André ROUGÉ",
       label: "André Rougé"
    },
    {
       value: "Mathilde ANDROUËT",
       label: "Mathilde Androuët"
    },
    {
       value: "Stelios KYMPOUROPOULOS",
       label: "Stelios Kympouropoulos"
    },
    {
       value: "Raphaël GLUCKSMANN",
       label: "Raphaël Glucksmann"
    },
    {
       value: "Anna-Michelle ASIMAKOPOULOU",
       label: "Anna-michelle Asimakopoulou"
    },
    {
       value: "Aurore LALUCQ",
       label: "Aurore Lalucq"
    },
    {
       value: "Pierre LARROUTUROU",
       label: "Pierre Larrouturou"
    },
    {
       value: "Elena KOUNTOURA",
       label: "Elena Kountoura"
    },
    {
       value: "Konstantinos ARVANITIS",
       label: "Konstantinos Arvanitis"
    },
    {
       value: "Lina GÁLVEZ MUÑOZ",
       label: "Lina Gálvez muñoz"
    },
    {
       value: "Robert ROOS",
       label: "Robert Roos"
    },
    {
       value: "Dolors MONTSERRAT",
       label: "Dolors Montserrat"
    },
    {
       value: "Ibán GARCÍA DEL BLANCO",
       label: "Ibán García del blanco"
    },
    {
       value: "Nicolás GONZÁLEZ CASARES",
       label: "Nicolás González casares"
    },
    {
       value: "Cristina MAESTRE MARTÍN DE ALMAGRO",
       label: "Cristina Maestre martín de almagro"
    },
    {
       value: "Frances FITZGERALD",
       label: "Frances Fitzgerald"
    },
    {
       value: "César LUENA",
       label: "César Luena"
    },
    {
       value: "Nacho SÁNCHEZ AMOR",
       label: "Nacho Sánchez amor"
    },
    {
       value: "Mónica Silvana GONZÁLEZ",
       label: "Mónica silvana González"
    },
    {
       value: "Clare DALY",
       label: "Clare Daly"
    },
    {
       value: "Lefteris NIKOLAOU-ALAVANOS",
       label: "Lefteris Nikolaou-alavanos"
    },
    {
       value: "Ioannis LAGOS",
       label: "Ioannis Lagos"
    },
    {
       value: "Lídia PEREIRA",
       label: "Lídia Pereira"
    },
    {
       value: "Athanasios KONSTANTINOU",
       label: "Athanasios Konstantinou"
    },
    {
       value: "Isabel GARCÍA MUÑOZ",
       label: "Isabel García muñoz"
    },
    {
       value: "Petros KOKKALIS",
       label: "Petros Kokkalis"
    },
    {
       value: "Sandra PEREIRA",
       label: "Sandra Pereira"
    },
    {
       value: "Vera TAX",
       label: "Vera Tax"
    },
    {
       value: "Milan UHRÍK",
       label: "Milan Uhrík"
    },
    {
       value: "Miroslav RADAČOVSKÝ",
       label: "Miroslav Radačovský"
    },
    {
       value: "Peter POLLÁK",
       label: "Peter Pollák"
    },
    {
       value: "Lucia ĎURIŠ NICHOLSONOVÁ",
       label: "Lucia Ďuriš nicholsonová"
    },
    {
       value: "Eugen JURZYCA",
       label: "Eugen Jurzyca"
    },
    {
       value: "Michal WIEZIK",
       label: "Michal Wiezik"
    },
    {
       value: "Martin HOJSÍK",
       label: "Martin Hojsík"
    },
    {
       value: "Vladimír BILČÍK",
       label: "Vladimír Bilčík"
    },
    {
       value: "Tineke STRIK",
       label: "Tineke Strik"
    },
    {
       value: "Bert-Jan RUISSEN",
       label: "Bert-jan Ruissen"
    },
    {
       value: "Rob ROOKEN",
       label: "Rob Rooken"
    },
    {
       value: "Leopoldo LÓPEZ GIL",
       label: "Leopoldo López gil"
    },
    {
       value: "Tom BERENDSEN",
       label: "Tom Berendsen"
    },
    {
       value: "José Ramón BAUZÁ DÍAZ",
       label: "José ramón Bauzá díaz"
    },
    {
       value: "Bart GROOTHUIS",
       label: "Bart Groothuis"
    },
    {
       value: "Malik AZMANI",
       label: "Malik Azmani"
    },
    {
       value: "Mohammed CHAHIM",
       label: "Mohammed Chahim"
    },
    {
       value: "Andris AMERIKS",
       label: "Andris Ameriks"
    },
    {
       value: "Susana SOLÍS PÉREZ",
       label: "Susana Solís pérez"
    },
    {
       value: "Eugenia RODRÍGUEZ PALOP",
       label: "Eugenia Rodríguez palop"
    },
    {
       value: "Susanna CECCARDI",
       label: "Susanna Ceccardi"
    },
    {
       value: "Antonio Maria RINALDI",
       label: "Antonio maria Rinaldi"
    },
    {
       value: "Anna BONFRISCO",
       label: "Anna Bonfrisco"
    },
    {
       value: "Massimiliano SMERIGLIO",
       label: "Massimiliano Smeriglio"
    },
    {
       value: "Daniela RONDINELLI",
       label: "Daniela Rondinelli"
    },
    {
       value: "Chiara GEMMA",
       label: "Chiara Gemma"
    },
    {
       value: "Mario FURORE",
       label: "Mario Furore"
    },
    {
       value: "Massimo CASANOVA",
       label: "Massimo Casanova"
    },
    {
       value: "Lucia VUOLO",
       label: "Lucia Vuolo"
    },
    {
       value: "Valentino GRANT",
       label: "Valentino Grant"
    },
    {
       value: "Eero HEINÄLUOMA",
       label: "Eero Heinäluoma"
    },
    {
       value: "Franco ROBERTI",
       label: "Franco Roberti"
    },
    {
       value: "Ville NIINISTÖ",
       label: "Ville Niinistö"
    },
    {
       value: "Dino GIARRUSSO",
       label: "Dino Giarrusso"
    },
    {
       value: "Silvia MODIG",
       label: "Silvia Modig"
    },
    {
       value: "Annalisa TARDINO",
       label: "Annalisa Tardino"
    },
    {
       value: "Pietro BARTOLO",
       label: "Pietro Bartolo"
    },
    {
       value: "Nils UŠAKOVS",
       label: "Nils Ušakovs"
    },
    {
       value: "Mick WALLACE",
       label: "Mick Wallace"
    },
    {
       value: "Billy KELLEHER",
       label: "Billy Kelleher"
    },
    {
       value: "Alexis GEORGOULIS",
       label: "Alexis Georgoulis"
    },
    {
       value: "Nicola PROCACCINI",
       label: "Nicola Procaccini"
    },
    {
       value: "Francesca DONATO",
       label: "Francesca Donato"
    },
    {
       value: "Giuseppe MILAZZO",
       label: "Giuseppe Milazzo"
    },
    {
       value: "Raffaele STANCANELLI",
       label: "Raffaele Stancanelli"
    },
    {
       value: "Matteo ADINOLFI",
       label: "Matteo Adinolfi"
    },
    {
       value: "Idoia VILLANUEVA RUIZ",
       label: "Idoia Villanueva ruiz"
    },
    {
       value: "Manu PINEDA",
       label: "Manu Pineda"
    },
    {
       value: "Jorge BUXADÉ VILLALBA",
       label: "Jorge Buxadé villalba"
    },
    {
       value: "Hermann TERTSCH",
       label: "Hermann Tertsch"
    },
    {
       value: "Diana RIBA I GINER",
       label: "Diana Riba i giner"
    },
    {
       value: "Aušra SEIBUTYTĖ",
       label: "Aušra Seibutytė"
    },
    {
       value: "Alexander ALEXANDROV YORDANOV",
       label: "Alexander Alexandrov yordanov"
    },
    {
       value: "Stasys JAKELIŪNAS",
       label: "Stasys Jakeliūnas"
    },
    {
       value: "Juozas OLEKAS",
       label: "Juozas Olekas"
    },
    {
       value: "Radan KANEV",
       label: "Radan Kanev"
    },
    {
       value: "Rasa JUKNEVIČIENĖ",
       label: "Rasa Juknevičienė"
    },
    {
       value: "Liudas MAŽYLIS",
       label: "Liudas Mažylis"
    },
    {
       value: "Elena YONCHEVA",
       label: "Elena Yoncheva"
    },
    {
       value: "Andrius KUBILIUS",
       label: "Andrius Kubilius"
    },
    {
       value: "Petar VITANOV",
       label: "Petar Vitanov"
    },
    {
       value: "Tsvetelina PENKOVA",
       label: "Tsvetelina Penkova"
    },
    {
       value: "Ivo HRISTOV",
       label: "Ivo Hristov"
    },
    {
       value: "Atidzhe ALIEVA-VELI",
       label: "Atidzhe Alieva-veli"
    },
    {
       value: "Andrey SLABAKOV",
       label: "Andrey Slabakov"
    },
    {
       value: "Maria WALSH",
       label: "Maria Walsh"
    },
    {
       value: "Samira RAFAELA",
       label: "Samira Rafaela"
    },
    {
       value: "Kim VAN SPARRENTAK",
       label: "Kim Van sparrentak"
    },
    {
       value: "Grace O'SULLIVAN",
       label: "Grace O'sullivan"
    },
    {
       value: "László TRÓCSÁNYI",
       label: "László Trócsányi"
    },
    {
       value: "Ivan Vilibor SINČIĆ",
       label: "Ivan vilibor Sinčić"
    },
    {
       value: "Márton GYÖNGYÖSI",
       label: "Márton Gyöngyösi"
    },
    {
       value: "Mazaly AGUILAR",
       label: "Mazaly Aguilar"
    },
    {
       value: "Roman HAIDER",
       label: "Roman Haider"
    },
    {
       value: "Mihai TUDOSE",
       label: "Mihai Tudose"
    },
    {
       value: "Estrella DURÁ FERRANDIS",
       label: "Estrella Durá ferrandis"
    },
    {
       value: "Emmanouil FRAGKOS",
       label: "Emmanouil Fragkos"
    },
    {
       value: "José Manuel GARCÍA-MARGALLO Y MARFIL",
       label: "José manuel García-margallo y marfil"
    },
    {
       value: "Marianne VIND",
       label: "Marianne Vind"
    },
    {
       value: "Isabel CARVALHAIS",
       label: "Isabel Carvalhais"
    },
    {
       value: "Sunčana GLAVAK",
       label: "Sunčana Glavak"
    },
    {
       value: "Marc ANGEL",
       label: "Marc Angel"
    },
    {
       value: "Gheorghe-Vlad NISTOR",
       label: "Gheorghe-vlad Nistor"
    },
    {
       value: "Carles PUIGDEMONT I CASAMAJÓ",
       label: "Carles Puigdemont i casamajó"
    },
    {
       value: "Antoni COMÍN I OLIVERES",
       label: "Antoni Comín i oliveres"
    },
    {
       value: "Barry ANDREWS",
       label: "Barry Andrews"
    },
    {
       value: "Salvatore DE MEO",
       label: "Salvatore De meo"
    },
    {
       value: "Vincenzo SOFO",
       label: "Vincenzo Sofo"
    },
    {
       value: "Alviina ALAMETSÄ",
       label: "Alviina Alametsä"
    },
    {
       value: "Miriam LEXMANN",
       label: "Miriam Lexmann"
    },
    {
       value: "Dominik TARCZYŃSKI",
       label: "Dominik Tarczyński"
    },
    {
       value: "Christian SAGARTZ",
       label: "Christian Sagartz"
    },
    {
       value: "Adrián VÁZQUEZ LÁZARA",
       label: "Adrián Vázquez lázara"
    },
    {
       value: "Marcos ROS SEMPERE",
       label: "Marcos Ros sempere"
    },
    {
       value: "Margarita DE LA PISA CARRIÓN",
       label: "Margarita De la pisa carrión"
    },
    {
       value: "Ilana CICUREL",
       label: "Ilana Cicurel"
    },
    {
       value: "Nora MEBAREK",
       label: "Nora Mebarek"
    },
    {
       value: "Sandro GOZI",
       label: "Sandro Gozi"
    },
    {
       value: "Claude GRUFFAT",
       label: "Claude Gruffat"
    },
    {
       value: "Jean-Lin LACAPELLE",
       label: "Jean-lin Lacapelle"
    },
    {
       value: "Clara PONSATÍ OBIOLS",
       label: "Clara Ponsatí obiols"
    },
    {
       value: "Riho TERRAS",
       label: "Riho Terras"
    },
    {
       value: "Dorien ROOKMAKER",
       label: "Dorien Rookmaker"
    },
    {
       value: "Heidi HAUTALA",
       label: "Heidi Hautala"
    },
    {
       value: "Chris MACMANUS",
       label: "Chris Macmanus"
    },
    {
       value: "Manuela RIPA",
       label: "Manuela Ripa"
    },
    {
       value: "Sara MATTHIEU",
       label: "Sara Matthieu"
    },
    {
       value: "Cyrus ENGERER",
       label: "Cyrus Engerer"
    },
    {
       value: "Vlad GHEORGHE",
       label: "Vlad Gheorghe"
    },
    {
       value: "Colm MARKEY",
       label: "Colm Markey"
    },
    {
       value: "Alin MITUȚA",
       label: "Alin Mituța"
    },
    {
       value: "Ernő SCHALLER-BAROSS",
       label: "Ernő Schaller-baross"
    },
    {
       value: "Emma WIESNER",
       label: "Emma Wiesner"
    },
    {
       value: "Thijs REUTEN",
       label: "Thijs Reuten"
    },
    {
       value: "Michiel HOOGEVEEN",
       label: "Michiel Hoogeveen"
    },
    {
       value: "Ladislav ILČIĆ",
       label: "Ladislav Ilčić"
    },
    {
       value: "Karolin BRAUNSBERGER-REINHOLD",
       label: "Karolin Braunsberger-reinhold"
    },
    {
       value: "Karsten LUCKE",
       label: "Karsten Lucke"
    },
    {
       value: "Fabienne KELLER",
       label: "Fabienne Keller"
    },
    {
       value: "Ilan DE BASSO",
       label: "Ilan De basso"
    },
    {
       value: "Catharina RINZEMA",
       label: "Catharina Rinzema"
    },
    {
       value: "René REPASI",
       label: "René Repasi"
    },
    {
       value: "Camilla LAURETI",
       label: "Camilla Laureti"
    },
    {
       value: "Rainer WIELAND",
       label: "Rainer Wieland"
    },
    {
       value: "Jan OVELGÖNNE",
       label: "Jan Ovelgönne"
    },
    {
       value: "Matjaž NEMEC",
       label: "Matjaž Nemec"
    },
    {
       value: "Michael GAHLER",
       label: "Michael Gahler"
    },
    {
       value: "Max ORVILLE",
       label: "Max Orville"
    },
    {
       value: "Marie DAUCHY",
       label: "Marie Dauchy"
    },
    {
       value: "Eric MINARDI",
       label: "Eric Minardi"
    },
    {
       value: "Patricia CHAGNON",
       label: "Patricia Chagnon"
    },
    {
       value: "Marina MESURE",
       label: "Marina Mesure"
    },
    {
       value: "Kateřina KONEČNÁ",
       label: "Kateřina Konečná"
    },
    {
       value: "Jan ZAHRADIL",
       label: "Jan Zahradil"
    },
    {
       value: "João ALBUQUERQUE",
       label: "João Albuquerque"
    },
    {
       value: "Eva Maria POPTCHEVA",
       label: "Eva maria Poptcheva"
    },
    {
       value: "Matthias ECKE",
       label: "Matthias Ecke"
    },
    {
       value: "Bogusław LIBERADZKI",
       label: "Bogusław Liberadzki"
    },
    {
       value: "Carina OHLSSON",
       label: "Carina Ohlsson"
    },
    {
       value: "Janusz LEWANDOWSKI",
       label: "Janusz Lewandowski"
    },
    {
       value: "Adam BIELAN",
       label: "Adam Bielan"
    },
    {
       value: "András GYÜRK",
       label: "András Gyürk"
    },
    {
       value: "Johan NISSINEN",
       label: "Johan Nissinen"
    },
    {
       value: "Theresa BIELOWSKI",
       label: "Theresa Bielowski"
    },
    {
       value: "Monika BEŇOVÁ",
       label: "Monika Beňová"
    },
    {
       value: "Matteo GAZZINI",
       label: "Matteo Gazzini"
    },
    {
       value: "Paola GHIDONI",
       label: "Paola Ghidoni"
    },
    {
       value: "Achille VARIATI",
       label: "Achille Variati"
    },
    {
       value: "Maria Angela DANZÌ",
       label: "Maria angela Danzì"
    },
    {
       value: "Denis NESCI",
       label: "Denis Nesci"
    },
    {
       value: "Elisabetta DE BLASIS",
       label: "Elisabetta De blasis"
    },
    {
       value: "Eleni STAVROU",
       label: "Eleni Stavrou"
    },
    {
       value: "Erik POULSEN",
       label: "Erik Poulsen"
    },
    {
       value: "Bergur Løkke RASMUSSEN",
       label: "Bergur løkke Rasmussen"
    },
    {
       value: "Beatrice COVASSI",
       label: "Beatrice Covassi"
    },
    {
       value: "Maria Veronica ROSSI",
       label: "Maria veronica Rossi"
    },
    {
       value: "Francesca PEPPUCCI",
       label: "Francesca Peppucci"
    },
    {
       value: "Nikos PAPANDREOU",
       label: "Nikos Papandreou"
    },
    {
       value: "Christophe CLERGEAU",
       label: "Christophe Clergeau"
    },
    {
       value: "Emmanuel MAUREL",
       label: "Emmanuel Maurel"
    },
    {
       value: "Thomas RUDNER",
       label: "Thomas Rudner"
    },
    {
       value: "Anne SANDER",
       label: "Anne Sander"
    },
    {
       value: "Catherine AMALRIC",
       label: "Catherine Amalric"
    },
    {
       value: "Anja HAGA",
       label: "Anja Haga"
    },
    {
       value: "Laura BALLARÍN CEREZA",
       label: "Laura Ballarín cereza"
    },
    {
       value: "Ana COLLADO JIMÉNEZ",
       label: "Ana Collado jiménez"
    },
    {
       value: "Pascal ARIMONT",
       label: "Pascal Arimont"
    },
    {
       value: "Laurence SAILLIET",
       label: "Laurence Sailliet"
    },
    {
       value: "Lydie MASSARD",
       label: "Lydie Massard"
    },
    {
       value: "Ana MIRANDA",
       label: "Ana Miranda"
    },
    {
       value: "Wolfram PIRCHNER",
       label: "Wolfram Pirchner"
    },
    {
       value: "Martine KEMP",
       label: "Martine Kemp"
    },
    {
       value: "Jozef MIHÁL",
       label: "Jozef Mihál"
    },
    {
       value: "Witold PAHL",
       label: "Witold Pahl"
    },
    {
       value: "Włodzimierz KARPIŃSKI",
       label: "Włodzimierz Karpiński"
    },
    {
       value: "Rafał ROMANOWSKI",
       label: "Rafał Romanowski"
    },
    {
       value: "Patricia CARO MAYA",
       label: "Patricia Caro maya"
    },
    {
       value: "François THIOLLET",
       label: "François Thiollet"
    },
    {
       value: "Esther SANZ SELVA",
       label: "Esther Sanz selva"
    },
    {
       value: "Michael KAUCH",
       label: "Michael Kauch"
    },
    {
       value: "Krzysztof BREJZA",
       label: "Krzysztof Brejza"
    },
    {
       value: "Guy LAVOCAT",
       label: "Guy Lavocat"
    },
    {
       value: "Niels GEUKING",
       label: "Niels Geuking"
    },
    {
       value: "Linus GLANZELIUS",
       label: "Linus Glanzelius"
    },
    {
       value: "Henk Jan ORMEL",
       label: "Henk jan Ormel"
    },
    {
       value: "Anabela RODRIGUES",
       label: "Anabela Rodrigues"
    },
    {
       value: "Vasco BECKER-WEINBERG",
       label: "Vasco Becker-weinberg"
    },
    {
       value: "Ana Miguel DOS SANTOS",
       label: "Ana miguel Dos santos"
    },
    {
       value: "Vânia NETO",
       label: "Vânia Neto"
    },
    {
       value: "Teófilo SANTOS",
       label: "Teófilo Santos"
    },
    {
       value: "Ricardo MORGADO",
       label: "Ricardo Morgado"
    },
    {
       value: "Edina TÓTH",
       label: "Edina Tóth"
    },
    {
       value: "David CASA",
       label: "David Casa"
    },
    {
       value: "Lívia JÁRÓKA",
       label: "Lívia Járóka"
    },
    {
       value: "Kinga GÁL",
       label: "Kinga Gál"
    },
    {
       value: "Margrete AUKEN",
       label: "Margrete Auken"
    },
    {
       value: "Daniel CASPARY",
       label: "Daniel Caspary"
    },
    {
       value: "Andreas SCHWAB",
       label: "Andreas Schwab"
    },
    {
       value: "Markus PIEPER",
       label: "Markus Pieper"
    },
    {
       value: "Christian EHLER",
       label: "Christian Ehler"
    },
    {
       value: "Manfred WEBER",
       label: "Manfred Weber"
    },
    {
       value: "Sophia IN 'T VELD",
       label: "Sophia In 't veld"
    },
    {
       value: "Jerzy BUZEK",
       label: "Jerzy Buzek"
    },
    {
       value: "Jan OLBRYCHT",
       label: "Jan Olbrycht"
    },
    {
       value: "Ljudmila NOVAK",
       label: "Ljudmila Novak"
    },
    {
       value: "Jacek SARYUSZ-WOLSKI",
       label: "Jacek Saryusz-wolski"
    },
    {
       value: "Iratxe GARCÍA PÉREZ",
       label: "Iratxe García pérez"
    },
    {
       value: "Patrizia TOIA",
       label: "Patrizia Toia"
    },
    {
       value: "Mercedes BRESSO",
       label: "Mercedes Bresso"
    },
    {
       value: "Javier MORENO SÁNCHEZ",
       label: "Javier Moreno sánchez"
    },
    {
       value: "Anna FOTYGA",
       label: "Anna Fotyga"
    },
    {
       value: "Ryszard CZARNECKI",
       label: "Ryszard Czarnecki"
    },
    {
       value: "Pilar del CASTILLO VERA",
       label: "Pilar Del castillo vera"
    },
    {
       value: "Antonio LÓPEZ-ISTÚRIZ WHITE",
       label: "Antonio López-istúriz white"
    },
    {
       value: "Francisco José MILLÁN MON",
       label: "Francisco josé Millán mon"
    },
    {
       value: "Alessandra MUSSOLINI",
       label: "Alessandra Mussolini"
    },
    {
       value: "Dimitrios PAPADIMOULIS",
       label: "Dimitrios Papadimoulis"
    },
    {
       value: "Roberts ZĪLE",
       label: "Roberts Zīle"
    },
    {
       value: "Inese VAIDERE",
       label: "Inese Vaidere"
    },
    {
       value: "Tatjana ŽDANOKA",
       label: "Tatjana Ždanoka"
    },
    {
       value: "Marc TARABELLA",
       label: "Marc Tarabella"
    },
    {
       value: "Younous OMARJEE",
       label: "Younous Omarjee"
    },
    {
       value: "Marian-Jean MARINESCU",
       label: "Marian-jean Marinescu"
    },
    {
       value: "Corina CREȚU",
       label: "Corina Crețu"
    },
    {
       value: "Robert HAJŠEL",
       label: "Robert Hajšel"
    },
    {
       value: "Marco CAMPOMENOSI",
       label: "Marco Campomenosi"
    },
    {
       value: "Aldo PATRICIELLO",
       label: "Aldo Patriciello"
    },
    {
       value: "Christel SCHALDEMOSE",
       label: "Christel Schaldemose"
    },
    {
       value: "Cristian-Silviu BUŞOI",
       label: "Cristian-silviu Buşoi"
    },
    {
       value: "Georg MAYER",
       label: "Georg Mayer"
    },
    {
       value: "Rovana PLUMB",
       label: "Rovana Plumb"
    },
    {
       value: "Iuliu WINKLER",
       label: "Iuliu Winkler"
    },
    {
       value: "Sirpa PIETIKÄINEN",
       label: "Sirpa Pietikäinen"
    },
    {
       value: "Othmar KARAS",
       label: "Othmar Karas"
    },
    {
       value: "Frédérique RIES",
       label: "Frédérique Ries"
    },
    {
       value: "Udo BULLMANN",
       label: "Udo Bullmann"
    },
    {
       value: "Angelika NIEBLER",
       label: "Angelika Niebler"
    },
    {
       value: "María Soraya RODRÍGUEZ RAMOS",
       label: "María soraya Rodríguez ramos"
    },
    {
       value: "Antonius MANDERS",
       label: "Antonius Manders"
    },
    {
       value: "Sergio BERLATO",
       label: "Sergio Berlato"
    },
    {
       value: "Lara WOLTERS",
       label: "Lara Wolters"
    },
    {
       value: "Brice HORTEFEUX",
       label: "Brice Hortefeux"
    },
    {
       value: "Kathleen VAN BREMPT",
       label: "Kathleen Van brempt"
    },
    {
       value: "Nicolae ŞTEFĂNUȚĂ",
       label: "Nicolae Ştefănuță"
    },
    {
       value: "Jan HUITEMA",
       label: "Jan Huitema"
    },
    {
       value: "Nadine MORANO",
       label: "Nadine Morano"
    },
    {
       value: "Charles GOERENS",
       label: "Charles Goerens"
    },
    {
       value: "Anna CAVAZZINI",
       label: "Anna Cavazzini"
    },
    {
       value: "José GUSMÃO",
       label: "José Gusmão"
    },
    {
       value: "Victor NEGRESCU",
       label: "Victor Negrescu"
    },
    {
       value: "Catherine GRISET",
       label: "Catherine Griset"
    },
    {
       value: "Jeroen LENAERS",
       label: "Jeroen Lenaers"
    },
    {
       value: "Helmut SCHOLZ",
       label: "Helmut Scholz"
    },
    {
       value: "Philippe LAMBERTS",
       label: "Philippe Lamberts"
    },
    {
       value: "Katarína ROTH NEVEĎALOVÁ",
       label: "Katarína Roth neveďalová"
    },
    {
       value: "Seán KELLY",
       label: "Seán Kelly"
    },
    {
       value: "Vilija BLINKEVIČIŪTĖ",
       label: "Vilija Blinkevičiūtė"
    },
    {
       value: "Waldemar TOMASZEWSKI",
       label: "Waldemar Tomaszewski"
    },
    {
       value: "Viktor USPASKICH",
       label: "Viktor Uspaskich"
    },
    {
       value: "Morten LØKKEGAARD",
       label: "Morten Løkkegaard"
    },
    {
       value: "Pascal CANFIN",
       label: "Pascal Canfin"
    },
    {
       value: "Evžen TOŠENOVSKÝ",
       label: "Evžen Tošenovský"
    },
    {
       value: "Bas EICKHOUT",
       label: "Bas Eickhout"
    },
    {
       value: "Ska KELLER",
       label: "Ska Keller"
    },
    {
       value: "Reinhard BÜTIKOFER",
       label: "Reinhard Bütikofer"
    },
    {
       value: "Arnaud DANJEAN",
       label: "Arnaud Danjean"
    },
    {
       value: "François ALFONSI",
       label: "François Alfonsi"
    },
    {
       value: "Martin HÄUSLING",
       label: "Martin Häusling"
    },
    {
       value: "Sabine VERHEYEN",
       label: "Sabine Verheyen"
    },
    {
       value: "Axel VOSS",
       label: "Axel Voss"
    },
    {
       value: "Jarosław KALINOWSKI",
       label: "Jarosław Kalinowski"
    },
    {
       value: "Peter JAHR",
       label: "Peter Jahr"
    },
    {
       value: "Lara COMI",
       label: "Lara Comi"
    },
    {
       value: "Róża THUN UND HOHENSTEIN",
       label: "Róża Thun und hohenstein"
    },
    {
       value: "Danuta Maria HÜBNER",
       label: "Danuta maria Hübner"
    },
    {
       value: "Monika HOHLMEIER",
       label: "Monika Hohlmeier"
    },
    {
       value: "Herbert DORFMANN",
       label: "Herbert Dorfmann"
    },
    {
       value: "Elżbieta Katarzyna ŁUKACIJEWSKA",
       label: "Elżbieta katarzyna Łukacijewska"
    },
    {
       value: "Ryszard Antoni LEGUTKO",
       label: "Ryszard antoni Legutko"
    },
    {
       value: "Tomasz Piotr PORĘBA",
       label: "Tomasz piotr Poręba"
    },
    {
       value: "Pablo ARIAS ECHEVERRÍA",
       label: "Pablo Arias echeverría"
    },
    {
       value: "Carlo FIDANZA",
       label: "Carlo Fidanza"
    },
    {
       value: "Rosa ESTARÀS FERRAGUT",
       label: "Rosa Estaràs ferragut"
    },
    {
       value: "Juan Fernando LÓPEZ AGUILAR",
       label: "Juan fernando López aguilar"
    },
    {
       value: "Tamás DEUTSCH",
       label: "Tamás Deutsch"
    },
    {
       value: "Ádám KÓSA",
       label: "Ádám Kósa"
    },
    {
       value: "Enikő GYŐRI",
       label: "Enikő Győri"
    },
    {
       value: "Jens GEIER",
       label: "Jens Geier"
    },
    {
       value: "Petra KAMMEREVERT",
       label: "Petra Kammerevert"
    },
    {
       value: "Cornelia ERNST",
       label: "Cornelia Ernst"
    },
    {
       value: "Karima DELLI",
       label: "Karima Delli"
    },
    {
       value: "Andrea COZZOLINO",
       label: "Andrea Cozzolino"
    },
    {
       value: "Dominique RIQUET",
       label: "Dominique Riquet"
    },
    {
       value: "Paolo DE CASTRO",
       label: "Paolo De castro"
    },
    {
       value: "Izaskun BILBAO BARANDICA",
       label: "Izaskun Bilbao barandica"
    },
    {
       value: "Birgit SIPPEL",
       label: "Birgit Sippel"
    },
    {
       value: "Milan ZVER",
       label: "Milan Zver"
    },
    {
       value: "Sandra KALNIETE",
       label: "Sandra Kalniete"
    },
    {
       value: "Gabriel MATO",
       label: "Gabriel Mato"
    },
    {
       value: "Sylvie GUILLAUME",
       label: "Sylvie Guillaume"
    },
    {
       value: "Eider GARDIAZABAL RUBIAL",
       label: "Eider Gardiazabal rubial"
    },
    {
       value: "Evelyn REGNER",
       label: "Evelyn Regner"
    },
    {
       value: "Guy VERHOFSTADT",
       label: "Guy Verhofstadt"
    },
    {
       value: "Marie TOUSSAINT",
       label: "Marie Toussaint"
    },
    {
       value: "Andrey KOVATCHEV",
       label: "Andrey Kovatchev"
    },
    {
       value: "Eva MAYDELL",
       label: "Eva Maydell"
    },
    {
       value: "Loránt VINCZE",
       label: "Loránt Vincze"
    },
    {
       value: "Alessandro PANZA",
       label: "Alessandro Panza"
    },
    {
       value: "Lena DÜPONT",
       label: "Lena Düpont"
    }
 ]